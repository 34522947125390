<template>
  <div class="animated fadeIn container">
    <CRow class="justify-content-md-center">
      <CCol sm="12" md="6">
        <CCard>
          <CCardHeader>{{$t("change_email")}}</CCardHeader>
          <CCardBody>
            <p>{{$t("email_message")}}</p>
            <p>{{$t("no_access_to_email")}} <a
              href="https://pokemonrevolution.net/forum/index.php?forums/general-complaint-area.38/">{{$t("general_complaints")}}</a>.</p>
            <CRow>
              <CCol sm="12">
                <CInput
                  :label="$t('new_email')"
                  placeholder=""
                  :isValid="email.length > 0 ? validateEmail(email) : null"
                  v-model="email" type="email"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" class="text-right">
                <vue-ladda button-class="btn btn-primary" :loading="loading" :disabled="!validateEmail(email)" @click="submit">
                  {{$t("change_email_button")}}
                </vue-ladda>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow><!--/.row-->

  </div>
</template>

<script>
  import RequestEmailChange from '../../graphql/RequestEmailChange.graphql'
  import i18n from '@/lang/lang'

  export default {
    data() {
      return {
        loading: false,
        email: ''
      }
    },
    methods: {
      validateEmail(email) {
        let re = /\S+@\S+\.\S+/;
        return re.test(email)
      },
      jwtData() {
        if (this.jwt) {
          let splits = this.jwt.split('.');
          if (splits.length < 2) {
            return null
          }
          return JSON.parse(atob(splits[1]))
        }
        return null
      },
      submit() {
        this.loading = true;
        this.$apollo.mutate({
          mutation: RequestEmailChange,
          variables: {
            newEmail: this.email,
          }
        }).then(data => {
          this.loading = false;
          this.email = '';
          this.EmailRequestSent();
          setTimeout(() => {
            this.$router.push('/')
          }, 2000)
        }).catch(data => {
          this.loading = false;
          this.email = '';
          this.EmailBlacklisted()
        })
      },
    },
    notifications: {
      EmailRequestSent: {
        type: 'info',
        title: i18n.t('request_was_sent'),
        message: i18n.t('request_confirmation')
      },
      EmailBlacklisted: {
        type: 'error',
        title: i18n.t('change_email'),
        message: i18n.t('email_blacklist')
      }
    }
  }
</script>
