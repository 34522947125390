<template>
  <div class="animated fadeIn container" v-if="!$apollo.queries.profile.loading">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <i class='fa fa-user'></i> {{$t("profile")}}
          </CCardHeader>
          <CCardBody>
            <template>
              <CListGroup>
                <CListGroupItem>
                  <CRow class="list-hover-item justify-content-between">
                    <CCol sm="4">
                      <small class="text-uppercase text-gray">{{$t("avatar")}}</small>
                    </CCol>
                    <CCol sm="4">
                      <p class="text-gray small">{{$t("avatar_message")}}</p>
                    </CCol>
                    <CCol sm="4">
                      <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + profile.username" alt=""
                           class="rounded-circle float-right" height="60" width="60"/>
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem  to="/profile/change-username">
                  <CRow class="list-hover-item justify-content-between">
                    <CCol sm="3">
                      <small class="text-uppercase text-gray">{{$t("username")}}</small>
                    </CCol>
                    <CCol sm="8">
                      <h5>{{profile.username}}</h5>
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem to="/profile/change-email">
                  <CRow class="list-hover-item justify-content-between">
                    <CCol sm="3">
                      <small class="text-uppercase text-gray">{{$t("email")}}</small>
                    </CCol>
                    <CCol sm="8">
                      <h5>{{profile.email}}</h5>
                      <small>{{$t("email_message")}}</small>
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem to="/profile/change-password">
                  <CRow class="list-hover-item justify-content-between">
                    <CCol sm="3">
                      <small class="text-uppercase text-gray">{{$t("password")}}</small>
                    </CCol>
                    <CCol sm="8">
                      <h5>********</h5>
                    </CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
              <CListGroupItem to="/profile/discord">
                <CRow class="list-hover-item justify-content-between">
                  <CCol sm="3">
                    <small class="text-uppercase text-gray">{{$t("discord_tag")}}</small>
                  </CCol>
                  <CCol sm="8">
                    <h5>{{(profile.discord) ? profile.discord : '-'}}</h5>
                    <small>{{$t("discord_tag_message")}}</small>
                  </CCol>
                </CRow>
              </CListGroupItem>
              </CListGroup>
            </template>
          </CCardBody>

        </CCard>
        <CCard>
          <CCardBody>
            <CRow align-h="between">
              <CCol sm="8">
                <h5> {{$t("fingerprint_title")}}
                </h5>
                <span>{{$t("fingerprint_message")}}</span>
              </CCol>
              <CCol sm="4" class="text-right">
                <CIcon size="4xl" name="cil-fingerprint"/>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <router-link to="/privacy">{{$t("go_to_privacy_settings")}}</router-link>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
  import Profile from "../../graphql/Profile.graphql"

  export default {
    name: 'Profile',
    data() {
      return {
        selected: [], // Must be an array reference!
        show: true
      }
    },
    apollo: {
      profile: {
        query: Profile
      }
    }
  }
</script>

