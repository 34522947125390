<template>
  <div class="animated fadeIn container">
    <CCard>
      <CAlert color="info">
        {{$t("new_language")}}
      </CAlert>

      <CCardHeader>{{$t("credits")}}</CCardHeader>
      <CCardBody>
        <h5>{{$t("big_thanks")}}:</h5>
        <template v-for="team in teams">
          <h6 class="mt-3">
            <span class="flag-icon" :class="'flag-icon-' + team.flag"/> {{$t(team.name)}}:</h6>
          <CRow class="mt-1">
            <CCol lg="3" md="6" sm="12" v-for="member in team.members" :key="member.name">
              <div class="media">
                <div class="credits">
                  <img class="" :src="member.url" alt="Generic placeholder image">
                </div>
                <div class="media-body">
                  <span class="mt-0 h6">{{$t(member.name)}}</span>
                  <p>{{$t(member.role)}}</p>
                </div>
              </div>
            </CCol>
          </CRow>
        </template>
      </CCardBody>
    </CCard>

  </div>
</template>
<!---->
<script>

  export default {
    data() {
      return {
        teams: [
          {
            name: 'de_team',
            flag: 'de',
            members: [
              {
                name: 'Walrosskastanie',
                url: 'https://character.pokemonrevolution.net/MSw1LDEyLDQsODM0LDAsODIz',
                role: 'translator'
              },
              {
                name: 'Dakivid',
                url: 'https://character.pokemonrevolution.net/MiwzLDI3LDQsNjk1LDAsODIz?4',
                role: 'quality_checker'
              },
              {
                name: 'SchattenDialga',
                url: 'https://character.pokemonrevolution.net/MSw3LDE1LDQsMTEwMiwwLDg2MQ==',
                role: 'quality_checker'
              },
              {
                name: 'PreHax',
                url: 'https://character.pokemonrevolution.net/MSw5LDI3LDAsMTU1MiwxNTg0LDA=?5',
                role: 'quality_checker'
              }
            ]
          },
          {
            name: 'gr_team',
            flag: 'gr',
            members: [
              {
                name: 'Theminho',
                url: 'https://character.pokemonrevolution.net/MCwxMywxMiw0LDkzOSwwLDgyMw==',
                role: 'translator'
              },
              {
                name: 'Alex',
                url: 'https://character.pokemonrevolution.net/NSw0LDIyLDQsMTEwNCwxNTg0LDE1OTE=?4',
                role:  'quality_checker'
              },
              {
                name: 'Saxy',
                url: 'https://character.pokemonrevolution.net/MCwzLDAsNCw5NDcsMTU4NCwxMDAx==',
                role:  'quality_checker'
              },
              {
                name: 'Markoscrete2',
                url: 'https://character.pokemonrevolution.net/Miw3LDI2LDEsNjk2LDEwODAsOTMy==',
                role:  'quality_checker'
              },
              {
                name: 'Fantazi0',
                url: 'https://character.pokemonrevolution.net/Miw2LDI3LDMsODgyLDAsMTAwMA==',
                role:  'quality_checker'
              },
              {
                name: 'snowpard',
                url: 'https://character.pokemonrevolution.net/MiwzLDAsMyw3ODgsMTA4MCww==?4',
                role:  'quality_checker'
              },
            ]
          },
          {
            name: 'fr_team',
            flag: 'fr',
            members: [
              {
                name: 'Letsgo',
                url: 'https://character.pokemonrevolution.net/MSw4LDE0LDIsNzAwLDEwOTUsNzYy?4',
                role: 'translator'
              },
              {
                name: 'Kaminokage',
                url: 'https://character.pokemonrevolution.net/MCw3LDI1LDEsMCwwLDgyNQ==?4',
                role:  'quality_checker'
              },
              {
                name: 'Abygaelle',
                url: 'https://character.pokemonrevolution.net/NCwxLDksMywxNDY5LDExNzcsNzk5==?4',
                role:  'quality_checker'
              },
            ]
          },
          {
            name: 'es_team',
            flag: 'es',
            members: [
              {
                name: 'Astro',
                url: 'https://character.pokemonrevolution.net/MCwzLDE2LDQsMTQwOSwxNTg0LDgyMw==?4',
                role: 'translator'
              },
              {
                name: 'Kimap',
                url: 'https://character.pokemonrevolution.net/Nyw4LDE4LDEsMTEwMCwxMDc5LDg2Ng==?4',
                role:  'quality_checker'
              },
              {
                name: 'BlazeLeiton',
                url: 'https://character.pokemonrevolution.net/MSw3LDI3LDAsOTM2LDEwOTQsODU3==?4',
                role:  'quality_checker'
              },
              {
                name: 'Diskuid',
                url: 'https://character.pokemonrevolution.net/MCw2LDAsMiw4NzUsMCww==?4',
                role:  'quality_checker'
              },
              {
                name: 'Liquid',
                url: 'https://character.pokemonrevolution.net/MCw4LDIwLDIsMTQwOSwxNTIzLDE1OTE==?4',
                role:  'quality_checker'
              },
              {
                name: 'Sylveon',
                url: 'https://character.pokemonrevolution.net/NCw4LDEzLDQsODc2LDExNzcsMTYyMg==?4',
                role:  'quality_checker'
              },
            ]
          },
          {
            name: 'br_team',
            flag: 'br',
            members: [
              {
                name: 'Nanoluck',
                url: 'https://character.pokemonrevolution.net/MCwzLDI3LDQsODgyLDExNzcsMTQ5Ng==?4',
                role: 'translator'
              },
              {
                name: 'Belzebel',
                url: 'https://character.pokemonrevolution.net/NSw4LDI0LDEsMTEwNiwxNDc3LDE0OTI==?4',
                role:  'quality_checker'
              },
              {
                name: 'MadFrost',
                url: 'https://character.pokemonrevolution.net/MCw4LDAsMSwxNTQyLDAsODIz==?4',
                role:  'quality_checker'
              },
              {
                name: 'Buyshinynair',
                url: 'https://character.pokemonrevolution.net/NCwxMiw2LDAsMCwwLDk0OA==?4',
                role:  'quality_checker'
              },
            ]
          },
          {
            name: 'it_team',
            flag: 'it',
            members: [
              {
                name: 'Nostazz',
                url: 'https://character.pokemonrevolution.net/MSwzLDYsNCwxNDA5LDAsODIz==?4',
                role: 'translator'
              },
              {
                name: 'Giorgio25',
                url: 'https://character.pokemonrevolution.net/MCw3LDI1LDAsMTExNywwLDgyMw==?4',
                role:  'quality_checker'
              },
              {
                name: 'NamelessHero27',
                url: 'https://character.pokemonrevolution.net/MCw3LDI3LDAsMTAzMCwwLDEwMzI==?4',
                role:  'quality_checker'
              },
              {
                name: 'Xarcom',
                url: 'https://character.pokemonrevolution.net/MSwxMyw2LDEsMTE0MywxMTc2LDk3Nw==?4',
                role:  'quality_checker'
              },
            ]
          },
        ]
      }
    }
  }
</script>
