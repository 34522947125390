<template>
  <div class="animated fadeIn container">
    <CRow class="justify-content-md-center">
      <CCol sm="12" md="6">
        <CCard>
          <CCardHeader>{{$t("change_email")}}</CCardHeader>
          <CCardBody>
            <p v-if="!jwtData">{{$t("invalid_token")}}</p>
            <template v-else>
              <p>{{$t("email_change_warning")}} {{jwtData.email}}</p>
              <vue-ladda button-class="btn btn-primary float-right" :loading="loading"
                         @click="submit">{{$t("change_email_button")}}
              </vue-ladda>
            </template>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow><!--/.row-->

  </div>
</template>

<script>
  import ChangeEmail from '../graphql/EmailChange.graphql'
  import i18n from '@/lang/lang'

  export default {
    data() {
      return {
        jwt: this.$route.params.token
      }
    },
    computed: {
      jwtData() {
        if (this.jwt) {
          let splits = this.jwt.split('.');
          if (splits.length < 2) {
            return null
          }
          return JSON.parse(atob(splits[1]))
        }
        return null
      },
    },
    methods: {
      validateEmail(email) {
        let re = /\S+@\S+\.\S+/;
        return re.test(email)
      },
      submit() {
        this.loading = true;
        this.$apollo.mutate({
          mutation: ChangeEmail,
          variables: {
            token: this.jwt
          }
        }).then(data => {
          this.loading = false;
          if (data.data.changeEmail) {
            this.EmailChanged();
            setTimeout(() => {
              this.$router.push('/')
            }, 2000)
          } else {
            this.InvalidToken()
          }
        })
      },
    },
    notifications: {
      EmailChanged: {
        type: 'success',
        title: i18n.t('email_change_success_title'),
        message: i18n.t('email_change_success_message'),
      },
      InvalidToken: {
        type: 'error',
        title: i18n.t('invalid_token'),
        message: i18n.t('token_invalid_message'),
      }
    }
  }
</script>
